import React from 'react';
import userConfig from '../../../config';

import Container from '../Container';
import HeaderImage from '../HeaderImage';
import Social from '../Social';
import H1 from '../H1';
import P from './P';
import Link from './Link';
import Wrapper from './Wrapper';

function titleCase(str) {
    return str.replace(/(^|\s)\S/g, function (t) {
        return t.toUpperCase()
    });
}

function Header({config, curLocation}) {
    const {name: clubName, description, social} = config;

    let {showHeaderImage: showHeader} = userConfig;
    let showHeaderDesc = true;

    if (typeof window !== 'undefined' && window.location.pathname !== '/') {
        showHeaderDesc = false;
    }

    return (
        <Container>
            <Wrapper>
                {showHeader && (
                    <HeaderImage/>
                )}

                {showHeaderDesc ?
                    <>
                        <H1><Link to="/">{clubName}</Link></H1>
                        <P>{description}</P>
                        {social &&
                        <Social
                            github={social.github}
                            instagram={social.instagram}
                            join={social.join}
                        />
                        }
                    </> :
                    <>
                        {curLocation && <h1 className={"loc-heading"}>
                            IoT {titleCase(curLocation)}s
                        </h1>
                        }
                    </>
                }
            </Wrapper>
        </Container>
    );
}

export default Header;