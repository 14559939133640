module.exports = {
  title: 'TinoIoT', // Required
  name: 'Cupertino IoT Club',
  author: 'TinoIoT Officers', // Required
  description: `Welcome to TinoIoT! Read our pinned posts below to get started : )`,
  primaryColor: '#73d2ee', // Required
  showHeaderImage: true,
  showShareButtons: true,
  postsPerPage: 5, // Required
  social: {
    github: 'https://github.com/TinoIoT',
    instagram: 'https://instagram.com/tino.iot',
    join: 'https://join.tinoiot.com',
  },
  siteUrl: 'https://tinoiot.com',
  postMDSources : ['pinned-posts', 'regular-posts', '_pre-production'],
  postMDNames   : ['pinned',       'regular',       'pre-prod']
};
