import {createGlobalStyle} from "styled-components";
import styledNormalize from "styled-normalize";

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    box-sizing: border-box;
  }

  body {
    background: #f8fafc;
    font-family: 'Open Sans', sans-serif; 
    line-height: 1.5;
    padding: 50px 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  img {
    max-width: 100%;
  }
  
  .categories-bar{
    position: fixed;
    top: min(200px, 20vh);
    left: calc(((((100vw - 870px) / 2) - 150px) / 3) + 15px);
    max-width: min(calc((100vw - 870px) / 2 - 50px), 200px);
    max-height: 55vh;
    overflow-x: hidden;
    text-overflow: ellipsis;
    transition: opacity 500ms ease, height 500ms ease;
    
    &.show-progress > .movable-content{
      position: relative;
    }
    
    &.show-progress > .movable-content::before{
      content: "";
      position: absolute;
      border-left: 3px solid black;
      height: calc(100% - 10px);
      left: 2px;
      top: 50%;
      opacity: 0.3;
      transform: translateY(-50%);
    }
    
    & > a:first-of-type{
      display: inline-block;
      outline: 0px transparent !important;
    }
    
    .same-line{
      display: inline-block;
      * {
        display: inline;
      }
      margin-bottom: 3px;
    }
    
    .title{
      font-size: 25px;
      color: #000;
      font-weight: 700;
      letter-spacing: -0.01em;
    }
    
    .sub-title{
      font-size: 14px;
      font-weight: 700;
      color: #333333;
      word-spacing: 1px;
      letter-spacing: -0.06em;
      white-space: nowrap;
    }
    
    .category-wrap{
      position: relative;
      padding: 5px 12px;
    
      a{
        text-decoration: none;
        .sub-links{
          font-size: 16px;
          font-weight: 700;
          opacity: 0.3;
          transition: opacity 100ms ease;
          color: #000;
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
      }
      &::before{
        content: "";
        position: absolute;
        border-left: 3px solid black;
        transition: opacity 100ms ease;
        height: 75%;
        left: 2px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
      }
      
      &:hover{
        cursor: pointer;
      }
    }
    .category-wrap[data-is-current], .category-wrap:hover{
      .sub-links{
        opacity: 1;
        transition: opacity 100ms ease;
      }
      
      &::before{
        opacity: 1;
        transition: opacity 100ms ease;
      }
    }
    
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    
    &::-webkit-scrollbar{
      width: 0;
      height: 0;
    }
  }

  .gatsby-highlight {
    border-bottom: 1px solid #e0e6ed;
    border-top: 1px solid #e0e6ed;
    margin: 15px -90px;
    padding: 0;

    pre[class*="language-"] {
      margin: 0;
      padding: 25px 60px 27px 140px;
    }
  }
  

  pre[class*="language-"] {
    background: rgba(245, 245, 245, 1);
    color: rgb(65, 76, 94);  
  }
  
  .dropdown-indicator > svg{
    display: none;
  }
  
  .navigation-anchor{
    pointer-events: none;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  @media only screen and (min-width: 871px) {
    .categories-bar{
      & > a {
        position: fixed;
        height: 40px;
        z-index: 99;
      }
      & > .movable-content{
        margin-top: 40px;
      }
      &::after{
        content: '';
        z-index: 3;
        position: fixed;
        height: 48px;
        width: min(calc((100vw - 870px) / 2 - 50px), 200px);
        top: calc(55vh + min(200px, 20vh) - 10.7px);
        background: rgb(249,250,252);
        background: linear-gradient(180deg, rgba(249,250,252,0) 0%, rgba(249,250,252,1) 30%, rgba(249,250,252,1) 100%);
      }
    }
    .categories-overflow-mask{
      z-index: 3;
      position: fixed;
      height: 48px;
      width: min(calc((100vw - 870px) / 2 - 50px), 200px);;
      background: rgb(249,250,252);
      background: linear-gradient(0deg, rgba(249,250,252,0) 0%, rgba(249,250,252,1) 30%, rgba(249,250,252,1) 100%);
      &.r-180{
        background: linear-gradient(1800deg, rgba(249,250,252,0) 0%, rgba(249,250,252,1) 30%, rgba(249,250,252,1) 100%);
      }
    }
  }

  @media only screen and (max-width: 870px) {
    body {
      padding: 15px 0;
    }
    
    article > div > p > code{
      word-break: break-all !important;
      display: block !important;
      padding: 0.2em !important;
    }
  
    .categories-bar{
      position: fixed;
      opacity: 0;
      height: 0;
      transition: height 500ms ease, opacity 250ms ease;
      background: #f9fafc;
      box-shadow: 0 8px 13px 0 rgba(0,0,0,0.05),0 6px 17px 0 rgba(0,0,0,0.04) !important;
      padding: 10px 10px 50px 10px;
    }
    
    .navigation-anchor{
      top: -75px;
    }
    
    .dropdown-indicator{
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: fixed;
      height: 75px !important;
      min-height: 75px !important;
      top: 0;
      width: 75px;
      border-radius: 0 0 20px 0;
      left: 0;
      box-shadow: 0 8px 13px 0 rgba(0,0,0,0.05),0 6px 17px 0 rgba(0,0,0,0.04) !important;
      transition: opacity 250ms ease, height 1s ease;
      background-color: #f9fafc;
      opacity: 0.95;
      z-index: 100;
      
      svg {
        width: 30% !important;
        height: 30% !important;
        max-height: 36px;
        margin: auto;
        opacity: 0.8;
        display: block;
      }
    }
    
    .dropdown-indicator.show-indicator{
      height: unset !important;
      min-height: 75px !important;
      box-shadow: unset !important;
      background-color: unset;
    
      .categories-bar{
        position: unset;
        transition: opacity 500ms ease, height 500ms ease;
        opacity: 1; 
        max-width: 100vw;
        width: 100vw;
        height: 100%;
        max-height: 90vh;
      }
      
      svg {
        transform: translate(-5px, -45px) rotate(180deg);
      }
    }
  
    .gatsby-highlight {
      margin: 15px -15px;

      pre[class*="language-"] {
        padding: 25px 40px;
      }
    }
  }
`;
export default GlobalStyle;
