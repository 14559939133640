import styled from 'styled-components';

const Wrapper = styled.header`
  margin: 0 auto;
  max-width: 650px;
  padding: 0 25px 25px;
  text-align: center;
  
  h1{
    line-height: 1.1;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .loc-heading{
    padding-top: 5px;
    margin-bottom: 0;
    font-size: 24px;
  }
  
  @media only screen and (max-width: 870px) {
    padding: 0 10px 20px 10px;
  }
`;

export default Wrapper;