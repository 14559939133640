import React from 'react';
import Link from 'gatsby-link';

import Wrapper from './Wrapper';

import {graphql, useStaticQuery} from 'gatsby';

import Img from 'gatsby-image'

const query = graphql`
  query HeaderImage{
   file(relativePath: { eq: "main.png" }) {
      childImageSharp {
        fixed(width: 320, height: 320) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

function HeaderImage() {
    const fixedImage = useStaticQuery(query).file.childImageSharp.fixed;

    return (
        <Wrapper>
            <Link to="/">
                <Img fixed={fixedImage} alt={'IoT Logo'}/>
            </Link>
        </Wrapper>
    );
}

export default HeaderImage;
