import styled from 'styled-components';

const Wrapper = styled('div')`
  border: 5px solid #42c2e8;
  border-radius: 50%;
  height: 100px;
  margin: 0 auto 15px auto;
  overflow: hidden;
  width: 100px;

  .gatsby-image-wrapper {
    width: 100px !important;
    height: 100px !important;
    margin: -5px;
    border-radius: 50%;
    img {
      padding: 10px;
      border-radius: 50%;
    }
  }
  
  @media only screen and (max-width: 870px) {
    height: 85px;
    width: 85px;
    margin-top: 15px;
    margin-bottom: 0px;
    
      .gatsby-image-wrapper {
        width: 85px !important;
        height: 85px !important;
        margin: -5px;
        border-radius: 50%;
        img {
          padding: 10px;
          border-radius: 50%;
        }
      }
  }
`;

export default Wrapper;